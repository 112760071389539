@import '../../styles/_index.scss';

.wrapper {
    min-height: 100%; // for sticky footer
    display: flex; // for sticky footer
    flex-direction: column; // for sticky footer
}

.main {
    flex-grow: 1; // for sticky footer
    margin-top: rem-calc(48); // offset for header

    @media (min-width: $breakpoints-lg) {
        margin-top: rem-calc(60); // offset for header
    }

    > div {
        scroll-margin: rem-calc(48);

        @media (min-width: $breakpoints-lg) {
            scroll-margin: rem-calc(60 + 57); // offset for header
        }
    }
}