@import '../../styles/_index.scss';

.application-callout {
    display: flex;
    align-items: center;
    min-height: rem-calc(270);
    border: 1px solid $color-gray-option-bg;

    &-wrapper {
        padding: rem-calc(20) 0;

        @media (min-width: $breakpoints-lg) {
            padding: rem-calc(26) 0;
        }
    }

    &-img {
        display: none;
        height: rem-calc(270);

        @media (min-width: $breakpoints-lg) {
            display: inline-block;
            //spadding: 0 rem-calc(24);
        }
    }

    &-content {
        margin: 0;
        padding: rem-calc(32);

        @media (min-width: $breakpoints-lg) {
            margin: 0 auto;
            padding: rem-calc(10) rem-calc(20);
        }

        &-header {
            font-size: rem-calc(22);

            span {
                white-space: nowrap;
            }
        }

        ol, ul {
            padding: rem-calc(10) rem-calc(20);

            & > li::marker {
                font-weight: bold;
            }
        }

        &-pdf-links {
            display: flex;
            flex-wrap: wrap;
            gap: rem-calc(15) rem-calc(30);

            a {
                display: flex;
                align-items: center;
                gap: rem-calc(5);
                font-size: rem-calc(14);
                text-decoration: none;
                color: inherit;

                &:visited {
                    color: inherit;
                }
            }
        }
    }
}