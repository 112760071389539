@import '../../styles/_index.scss';

.calculator-tooltip {
    &-button {
        margin-left: rem-calc(10) !important;
        width: rem-calc(18);
        height: rem-calc(18);
        background-image: url('../../images/icon-tooltip.svg');
        background-size: cover;
        background-repeat: no-repeat;

        &:global(.open), &:focus, &:hover {
            background-image: url('../../images/icon-tooltip-open.svg');
        }
    }

    &-popper {
        margin-right: rem-calc(-18) !important;

        :global(.MuiTooltip-tooltip) {
            width: rem-calc(200);
            background-color: $color-navy;
            margin: rem-calc(14) 0 0 0;
            padding: rem-calc(24) rem-calc(24) rem-calc(20) rem-calc(20);
            border-radius: 0;
        }

        :global(.MuiTooltip-arrow) {
            position: absolute;
            left: auto !important;
            top: rem-calc(-8) !important;
            right: rem-calc(22) !important;
            margin: 0 !important;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99998 0L12 8H-1.94088e-05L5.99998 0Z' fill='%23282B3E'/%3E%3C/svg%3E%0A");
            width: rem-calc(12);
            height: rem-calc(8);
            background-size: cover;
            transform: none !important;

            &::before {
                content: none;
            }
        }

        &[data-popper-placement="top-end"] {
            :global(.MuiTooltip-arrow) {
                top: auto !important;
                transform: rotate(180deg) !important;
                bottom: rem-calc(-8) !important;
            }
        }

        &[data-popper-placement="bottom-start"] {
            :global(.MuiTooltip-arrow) {
                left: rem-calc(2) !important;
                right: auto !important;
            }
        }

        &[data-popper-placement="top-start"] {
            :global(.MuiTooltip-arrow) {
                top: auto !important;
                transform: rotate(180deg) !important;
                bottom: rem-calc(-8) !important;
                left: rem-calc(2) !important;
                right: auto !important;
            }
        }
    }

    &-inner {
        position: relative;
        color: $color-white;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        font-weight: 300;

        strong {
            font-weight: 700;
        }

        &-close {
            position: absolute !important;
            top: rem-calc(-20);
            right: rem-calc(-20);
            padding: rem-calc(4) !important;

            &-icon {
                width: rem-calc(12);
                height: rem-calc(12);
            }
        }
    }
}