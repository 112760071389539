@import '../../styles/_index.scss';

.intro-content {
    padding: rem-calc(20) 0;

    @media (min-width: $breakpoints-lg) {
        padding: rem-calc(26) 0;
    }

    p {
        margin: 0 0 rem-calc(16) 0;

        &:last-child {
            margin: 0;
        }
    }
}