@import '../../styles/_index.scss';

.faq-accordions {

    &-wrapper {
        padding: rem-calc(25) 0;
        background-color: $color-gray-light;
        scroll-margin-top: rem-calc(40);
    }

    &-title {
        font-size: rem-calc(26);
        font-weight: 600;
        line-height: rem-calc(35);
        letter-spacing: rem-calc(0);
        padding: 0 0 rem-calc(10);
        margin: 0;
    }

    .accordion {
        margin: 0 0 rem-calc(16);
    }

}