@import '../../styles/_index.scss';

.quick-links {

    @media (min-width: $breakpoints-lg) {
        &.fixed {
            position: fixed;
            background-color: $color-white;
            width: 100%;
            top: rem-calc(60);
            left: 0;
            padding: rem-calc(15) rem-calc(10);
            box-shadow: 0 0.6em 1em -1em rgba(0, 0, 0, .25);
            z-index: 1499;
        }
    }

    &-wrapper {
        display: none;
        scroll-margin-top: rem-calc(80);
        padding-top: rem-calc(26);

        @media (min-width: $breakpoints-lg) {
            display: block;
        }
    }

    &-container {

        @media (min-width: $breakpoints-lg) {
            &.fixed {
                position: initial;
                padding-bottom: rem-calc(70);
            }
        }
    }

    &-title {
        margin-bottom: rem-calc(17);
        font-size: rem-calc(20);
        line-height: rem-calc(26.76);

        &.fixed {
            display: none;
        }
    }

    &-nav {
        display: flex;
        gap: rem-calc(22);
        list-style: none;
        margin: 0;
        padding: 0;

        &.fixed {
            width: rem-calc(990);
            margin: 0 auto;
        }

        li {
            padding-bottom: rem-calc(3);

            a, &:visited, &:focus {
                font-weight: 600;
                color: $color-green-dark;
                text-underline-offset: rem-calc(3);
                text-decoration-thickness: rem-calc(1.5);
                outline-offset: rem-calc(4);

                &.active {
                    font-weight: 400;
                    color: inherit;
                    text-decoration: none;
                }
            }

            &:not(:last-child) {
                border-right: rem-calc(1.5) solid $color-gray-french;
                padding-right: rem-calc(22);
            }
        }
    }
}