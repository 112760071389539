// Breakpoints: must match the ones defined in theme.js
$breakpoints-sm: rem-calc(375);
$breakpoints-md: rem-calc(768);
$breakpoints-lg: rem-calc(1024);
$breakpoints-xl: rem-calc(1280);
$breakpoints-xxl: rem-calc(1920);

$color-navy-light: #424559;
$color-navy: #282B3E;
$color-navy-darker: #202232;
$color-navy-lighter: #5E6073;
$color-navy-light2: #34384B;

$color-coral: #EC6453;
$color-coral-dark: #DB1F00;
$color-coral-light: #FF7769;
$color-green: #00A758;
$color-green-light: #00C46E;
$color-green-lighter: #ACE5C4;
$color-green-dark: #008048;
$color-green-dark2: #06874E;
$color-green-darker: #046138;
$color-white: #ffffff;
$color-gray-option-bg: #ededed;
$color-gray-option-disabled: #5E6073;
$color-gray-light: #fafafa;
$color-gray-mercury: #e3e3e3;
$color-gray-french: #C2C3C8;
$color-gray-mischka: #DDDEE4;
$color-gray-input: #B9B9B9;
$color-error-orange: #FCC457;

$font-manulife-sans: 'Manulife Sans';
$font-manulife-serif: 'Manulife Serif';