@import '../../styles/_index.scss';

.callouts {
    padding-bottom: rem-calc(15);

    @media (min-width: $breakpoints-lg) {
        display: flex;
        margin: 0 rem-calc(-10);
        padding-bottom: rem-calc(20);
    }

    &-wrapper {
        padding: rem-calc(20) 0;
        background: $color-gray-light;
        scroll-margin-top: rem-calc(50);

        @media (min-width: $breakpoints-lg) {
            padding: rem-calc(26) 0;
            scroll-margin-top: rem-calc(120);
        }
    }

    &-item {
        border: rem-calc(1) solid $color-gray-option-bg;
        padding: rem-calc(20) rem-calc(10);
        background: $color-white;

        @media (min-width: $breakpoints-lg) {
            margin: 0 rem-calc(10) !important;
            padding: rem-calc(20) rem-calc(25);
            width: calc(50% - #{rem-calc(10)});
        }

        &:first-child {
            margin-bottom: rem-calc(15);
        }

        &-header {
            padding: 0 rem-calc(10);
            margin-bottom: rem-calc(15);
            font-weight: 600;
            font-size: rem-calc(22);

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(16);
                text-align: center;
            }
        }

        &-content {
            ul {
                margin: 0;
                padding-left: rem-calc(30);
            }
        }
    }
}

.accordion {
    border-color: $color-gray-mischka;

    &-header {
        background-color: $color-gray-option-bg !important;

        :global(.MuiAccordionSummary-content) {
            font-size: rem-calc(18) !important;

            @media (min-width: $breakpoints-lg) {
                font-size: rem-calc(20) !important;
                font-weight: 600 !important;
            }
        }

        &[aria-expanded="true"] {
            background-color: transparent !important;
        }
    }

    &-content {
        @media (min-width: $breakpoints-lg) {
            display: flex;
            padding-bottom: rem-calc(30) !important;
        }

        &-column {
            position: relative;
            padding-bottom: rem-calc(42);

            @media (min-width: $breakpoints-lg) {
                padding-bottom: 0;
                width: 50%;
            }

            &:first-child {
                @media (min-width: $breakpoints-lg) {
                    padding-right: rem-calc(50);
                }
            }

            &:last-child {
                padding-top: rem-calc(23);

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: rem-calc(-50);
                    right: rem-calc(-50);
                    display: block;
                    border-top: rem-calc(1) solid $color-navy-lighter;
                }

                @media (min-width: $breakpoints-lg) {
                    padding-top: 0;
                    padding-left: rem-calc(50);

                    &::before {
                        left: 0;
                        top: 0;
                        bottom: 0;
                        border-top: none;
                        border-left: rem-calc(1) solid $color-navy-lighter;
                    }
                }
            }

            &-header {
                margin-bottom: rem-calc(10);
                font-size: rem-calc(18);
                font-weight: 600;

                @media (min-width: $breakpoints-lg) {
                    margin-bottom: rem-calc(24);
                    font-size: rem-calc(30);
                }
            }

            &-content {
                font-size: rem-calc(14);
                line-height: 1.5625;

                @media (min-width: $breakpoints-lg) {
                    font-size: rem-calc(16);
                }

                ul {
                    margin: 0;
                    padding-left: rem-calc(24);

                    li {
                        small {
                            font-size: rem-calc(11);

                            @media (min-width: $breakpoints-lg) {
                                font-size: rem-calc(12);
                            }
                        }
                    }
                }
            }
        }
    }
}
