@import '../../styles/_index.scss';

.modal {
    :global(.MuiBackdrop-root) {
        background-color: rgba(0,0,0,0.4);
    }

    :global(.MuiPaper-root) {
        margin: 0;
        max-height: initial !important;
        width: 100%;
        max-width: rem-calc(450);
        box-shadow: none;
        border: rem-calc(1) solid $color-navy-light;
        border-radius: 0;
    }

    &-top {
        padding: rem-calc(50) rem-calc(35) rem-calc(26) rem-calc(35);
        background-color: $color-navy-light;

        &-header {
            text-align: center;
            color: $color-white;
            em {
                font-family: $font-manulife-serif;
            }
        }
    }

    &-content {
        padding: rem-calc(50) rem-calc(35) rem-calc(60) rem-calc(35);
        text-align: center;

        &-question {
            margin-top: rem-calc(25);
            margin-bottom: rem-calc(20);
            font-weight: 700;
            letter-spacing: rem-calc(-0.176);
        }

        &-selector {
            &-label {
                top: rem-calc(-2) !important;
                left: rem-calc(3) !important;
                font-size: rem-calc(18) !important;
                line-height: 1.1 !important;
                font-weight: 400 !important;

                :global(.has-value) & {
                    opacity: 0;
                    line-height: 1.1 !important;
                }
            }

            :global(.MuiAutocomplete-endAdornment) {
                right: 3px !important;
            }

            :global(.MuiInputBase-input) {
                font-size: rem-calc(18) !important;
            }
        }

        &-continue {
            margin-top: rem-calc(46) !important;
            min-width: rem-calc(180) !important;
        }
    }
}

:global(.MuiAutocomplete-listbox) {
    padding-top: 0 !important;

    :global(.MuiAutocomplete-option)[aria-disabled="true"] {
        background-color: $color-gray-option-bg !important;
        color: $color-gray-option-disabled !important;
        font-weight: 400 !important;
    }
}