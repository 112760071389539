@import '../../styles/_index.scss';

.calculator-quiz-modal {
    :global(.MuiPaper-root) {
        max-width: rem-calc(400);
        border-radius: 0;
        background-color: $color-navy-darker;
        color: $color-white;

        @media (min-width: $breakpoints-lg) {
            width: rem-calc(800);
            max-width: initial;
        }
    }

    &.success {
        :global(.MuiPaper-root) {
            background-color: $color-white;
        }
    }

    &-close {
        position: absolute !important;
        top: 0 !important;
        right: rem-calc(6) !important;
        padding: rem-calc(14) !important;

        @media (min-width: $breakpoints-lg) {
            padding: rem-calc(16) !important;
            right: rem-calc(4) !important;
        }

        &-icon {
            @media (min-width: $breakpoints-lg) {
                width: rem-calc(13);
                height: rem-calc(13);
            }
        }
    }

    &-form {
        padding: rem-calc(60) rem-calc(15) rem-calc(22) rem-calc(15);

        @media (min-width: $breakpoints-lg) {
            padding: rem-calc(34) rem-calc(24) rem-calc(44) rem-calc(24);
        }

        &-header {
            margin-bottom: rem-calc(30);
            font-size: rem-calc(22);
            line-height: rem-calc(28);
            font-weight: 600;

            strong {
                font-size: rem-calc(33);
                font-weight: 700;
                line-height: rem-calc(44);
            }

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(35);
                font-size: rem-calc(28);
                line-height: rem-calc(38);

                strong {
                    font-size: rem-calc(44);
                    line-height: rem-calc(60);
                }
            }
        }

        &-input {
            width: 100%;
            padding-bottom: rem-calc(40) !important;

            @media (min-width: $breakpoints-lg) {
                max-width: rem-calc(620);
            }

            &-label {
                top: rem-calc(-4) !important;
                overflow: visible !important;
                font-weight: 400 !important;

                sup {
                    position: absolute;
                    top: rem-calc(-3);
                }

                :global(.has-value) & {
                    opacity: 0;
                }
            }

            :global(.MuiInputBase-input) {
                background-color: $color-white;
                border-radius: 0 !important;
            }
            :global(.MuiFormHelperText-root) {
                position: absolute;
                bottom: rem-calc(16);
                margin: 0;
                font-size: rem-calc(13);
                font-weight: 600;
                line-height: 1;
                letter-spacing: rem-calc(-0.143);
                color: $color-coral-light !important;

                @media (min-width: $breakpoints-lg) {
                    font-size: rem-calc(14);
                }
            }
            :global(.Mui-error) {
                :global(.MuiOutlinedInput-notchedOutline) {
                    border-color: $color-gray-input !important;
                }
            }

            :global(.Mui-focused) {
                :global(.MuiOutlinedInput-notchedOutline) {
                    border-color: $color-navy !important;
                }
            }
        }

        &-footer {
            margin: 0 rem-calc(-15);

            @media (min-width: $breakpoints-lg) {
                margin: 0;
                display: flex;
                align-items: center;
            }

            &-submit {
                width: 100%;

                @media (min-width: $breakpoints-lg) {
                    width: auto;
                    min-width: rem-calc(200);
                }
            }

            &-disclaimer {
                padding: 0 rem-calc(15);
                margin-top: rem-calc(18);
                font-size: rem-calc(11);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.121);

                @media (min-width: $breakpoints-lg) {
                    padding: 0;
                    margin: 0 0 0 rem-calc(28);
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                    letter-spacing: rem-calc(-0.154);
                }
            }
        }
    }

    &-success {
        color: $color-navy-darker;

        &-header {
            display: flex;
            flex-direction: column;
            padding: rem-calc(28) rem-calc(24) rem-calc(32) rem-calc(24);
            font-size: rem-calc(26);
            line-height: rem-calc(34);

            @media (min-width: $breakpoints-lg) {
                display: block;
                padding: rem-calc(60) rem-calc(26) rem-calc(26) rem-calc(26);
                font-size: rem-calc(43);
                line-height: rem-calc(50);
            }

            &-icon {
                order: -1;
                margin-bottom: rem-calc(7);
                width: rem-calc(54);
                height: auto;

                @media (min-width: $breakpoints-lg) {
                    display: inline-block;
                    margin: 0 0 0 rem-calc(10);
                    vertical-align: bottom;
                }
            }
        }

        &-copy1, &-copy2 {
            font-size: rem-calc(18);
            line-height: 1.33;
            font-weight: 600;

            @media (min-width: $breakpoints-lg) {
                font-size: rem-calc(20);
            }
        }

        &-copy1 {
            padding: rem-calc(14) rem-calc(24) rem-calc(14) rem-calc(24);
            background-color: $color-navy-darker;
            color: $color-white;

            @media (min-width: $breakpoints-lg) {
                padding: rem-calc(12) rem-calc(26);
            }
        }

        &-copy2 {
            padding: rem-calc(26) rem-calc(24) rem-calc(44) rem-calc(24);

            @media (min-width: $breakpoints-lg) {
                padding: rem-calc(26) rem-calc(26) rem-calc(45) rem-calc(26);
            }
        }
    }
}