@import '../../styles/_index.scss';

.calculator-wrapper {
    padding-top: rem-calc(17);

    @media (min-width: $breakpoints-lg) {
        padding-top: rem-calc(26)
    }
}

.calculator {
    border: rem-calc(1) solid $color-navy-darker;
    border-bottom: 0;

    @media (min-width: $breakpoints-lg) {
        display: flex;
        flex-direction: column;
        min-height: rem-calc(560);
    }

    &-top {
        padding: rem-calc(20) rem-calc(35);
        background-color: $color-navy;
        color: $color-white;
        text-align: center;

        @media (min-width: $breakpoints-lg) {
        }

        &-header {
            margin-bottom: rem-calc(5);
            font-size: rem-calc(26);
            line-height: rem-calc(35);
            font-weight: 700;

            @media (min-width: $breakpoints-lg) {
                font-size: rem-calc(30);
                line-height: rem-calc(40);
            }
        }

        &-subheader {
            font-size: rem-calc(20);
            line-height: rem-calc(26.76);

            @media (min-width: $breakpoints-lg) {
                font-size: rem-calc(25);
                line-height: rem-calc(33);
            }
        }
    }

    &-questions {
        padding: rem-calc(30) rem-calc(25);
        background-color: $color-gray-option-bg;

        @media (min-width: $breakpoints-lg) {
            display: flex;
            padding: rem-calc(31) rem-calc(100);
        }

        &-column {
            margin-bottom: rem-calc(33);

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: $breakpoints-lg) {
                margin: 0 rem-calc(162) 0 0;

                &:last-child {
                    margin: 0;
                }
            }

            &-control {
                &-label {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem-calc(16);
                }

                &-radio {
                    display: flex !important;
                    flex-direction: row !important;

                    @media (min-width: $breakpoints-lg) {
                        flex-wrap: nowrap !important;
                    }

                    :global(.MuiFormControlLabel-root) {
                        margin: 0 rem-calc(20) 0 0 !important;

                        &:hover {
                            :global(.MuiRadio-root):not(:global(.Mui-checked)) {
                                svg circle {
                                    stroke: $color-gray-option-disabled;
                                    stroke-width: 3;
                                    r: 12;
                                }
                            }
                        }
                    }

                    :global(.MuiFormControlLabel-label) {
                        font-weight: 300;
                    }

                    :global(.MuiRadio-root) {
                        margin-right: rem-calc(10) !important;

                        &:not(:global(.Mui-checked)) {
                            input:focus-visible + svg circle{
                                stroke: $color-gray-option-disabled;
                                stroke-width: 3;
                                r: 12;
                            }
                        }
                    }
                }

                &-textfield {
                    margin-top: rem-calc(-8) !important;

                    :global(.MuiInputBase-root) {
                        width: rem-calc(140);
                    }

                    :global(.MuiInputBase-input) {
                    }

                    :global(.MuiFormHelperText-root.Mui-error) {
                        margin: rem-calc(4) 0 0 0;
                        color: $color-coral-dark;
                        font-size: rem-calc(13);
                        line-height: rem-calc(16);
                    }

                    :global(.Mui-error) {
                        :global(.MuiOutlinedInput-notchedOutline) {
                            border-color: $color-coral-dark !important;
                            border-width: rem-calc(2) !important;
                        }
                    }

                    :global(.Mui-focused):not(:global(.Mui-error)) {
                        :global(.MuiOutlinedInput-notchedOutline) {
                            border-color: $color-navy-light !important;
                        }
                    }

                    &:global(.has-valid-value) {
                        :global(.MuiOutlinedInput-notchedOutline) {
                            border-color: $color-green !important;
                            border-width: rem-calc(2) !important;
                        }
                    }
                }
            }
        }
    }

    &-quotes {
        padding: rem-calc(17) rem-calc(20);

        @media (min-width: $breakpoints-lg) {
            display: flex;
            justify-content: space-between;
            padding: rem-calc(17) rem-calc(24 - 10);
        }
    }

    &-bottom {
        padding: rem-calc(20);
        background-color: $color-green-dark2;

        @media (min-width: $breakpoints-lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: rem-calc(17) rem-calc(55);
        }

        &-title {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(25);
            line-height: rem-calc(33.45);
            color: $color-white;

            @media (min-width: $breakpoints-lg) {
                margin: 0 rem-calc(19) 0 0;
                font-size: rem-calc(28);
                line-height: rem-calc(37);
            }
        }

        &-savequote {
            width: 100%;

            &:global(.Mui-disabled) {
                opacity: 0.7 !important;
                border-color: $color-coral !important;
                color: $color-coral !important;
            }

            @media (min-width: $breakpoints-lg) {
                width: rem-calc(260);
            }
        }
    }
}