@import '../../styles/_index.scss';

.header {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    right: 0;
    padding: rem-calc(12) 0 rem-calc(12) 0;
    background-color: $color-navy-light;
    color: $color-white;
    font-size: 0;

    @media (min-width: $breakpoints-lg) {
        padding: rem-calc(15) 0;
    }

    &-logo {
        font-size: 0;

        &-image {
            height: rem-calc(24);
            width: auto;

            @media (min-width: $breakpoints-lg) {
                height: rem-calc(30);
            }
        }
    }

    &-lang {
        font-size: rem-calc(16);
        line-height: 1;
        color: $color-white;
        font-weight: 700;

        @media (min-width: $breakpoints-lg) {
            font-size: rem-calc(18);
        }
    }

    &-toggle {
        margin-left: rem-calc(20) !important;

        &-icon {
            height: rem-calc(16);
            width: auto;
        }

        &-menu {
            z-index: 2000 !important;

            ul {
                background-color: $color-navy-light;
                padding: rem-calc(5) rem-calc(5) rem-calc(15) !important;

                li {
                    padding: rem-calc(2);

                    a {
                        display: block;
                        width: 100%;
                        padding: rem-calc(5) rem-calc(15);
                        font-size: rem-calc(14);
                        color: $color-white;
                        text-decoration: none;
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }
}