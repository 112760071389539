@import '../../styles/_index.scss';

.calculator-quotes-column {
    border: rem-calc(1) solid $color-navy;
    padding: rem-calc(20) rem-calc(15);
    margin-bottom: rem-calc(17);

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: $breakpoints-lg) {
        margin: 0 rem-calc(10);
        padding: rem-calc(20) rem-calc(40);
        width: calc(50% - #{rem-calc(20)});
    }

    &-top {
        :global(html[lang="fr"]) & {
            min-height: rem-calc(185);
        }
    }

    &-header {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        font-weight: 700;

        @media (min-width: $breakpoints-lg) {
            margin: 0 rem-calc(-10) rem-calc(18) rem-calc(-10);
            font-size: rem-calc(24);
            line-height: rem-calc(32);
        }
    }

    &-content {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(14);
        line-height: rem-calc(20);

        ul {
            margin: 0;
            padding-left: rem-calc(22);

            @media (min-width: $breakpoints-lg) {
                margin: 0 rem-calc(-20);
            }
        }

        sup {
            line-height: rem-calc(12);
        }
    }

    &-slider {
        position: relative;
        margin-top: rem-calc(20);

        &-label {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(14);
            line-height: rem-calc(24);

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(11);
                font-size: rem-calc(18);
            }
        }

        &-input {
            width: 100%;
            align-items: center;

            @media (min-width: $breakpoints-lg) {
                margin: 0 !important;
                position: absolute !important;
                bottom: rem-calc(-4);
                left: 50%;
                transform: translateX(-50%);
            }

            :global(.MuiInputBase-root) {
                max-width: rem-calc(126);
            }
            :global(.MuiInputBase-input) {
                text-align: center;
                font-size: rem-calc(18);
            }
            :global(.MuiOutlinedInput-notchedOutline) {
                border: rem-calc(1) solid $color-gray-input !important;
            }
        }

        &-control {
            margin-top: rem-calc(10);

            @media (min-width: $breakpoints-lg) {
                margin: 0;
            }
        }

        &-marks {
            display: flex;
            justify-content: space-between;
            font-size: rem-calc(14);
            line-height: rem-calc(18);
            font-weight: 700;

            @media (min-width: $breakpoints-lg) {
                margin-top: rem-calc(13);
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                font-weight: 600;
            }

            &-max {
                text-align: right;
            }
        }
    }

    &-results {
        margin-top: rem-calc(20);
        padding: rem-calc(20) 0;
        text-align: center;

        &-title {
            font-size: rem-calc(16);
            line-height: rem-calc(22);
        }

        &-price {
            font-weight: 700;
            font-size: rem-calc(38);
            line-height: rem-calc(51);
            opacity: 0.6;

            sup {
                font-weight: 600;
                font-size: rem-calc(18);
                line-height: rem-calc(24);
            }

            &:global(.has-value) {
                opacity: 1;
            }
        }

        &-priceunderline {
            margin-bottom: rem-calc(12);
            font-size: rem-calc(13);
            line-height: rem-calc(13);
        }

        &-coverage {
            font-size: rem-calc(16);
            strong {
                font-size: rem-calc(21);
                font-weight: 700;
            }
        }

        &-apply {
            margin-top: rem-calc(12) !important;
            margin-bottom: rem-calc(12) !important;
            width: 100%;

            &:global(.Mui-disabled) {
                opacity: 0.7 !important;
                background-color: $color-coral !important;
                color: $color-white !important;
            }

            @media (min-width: $breakpoints-lg) {
                width: rem-calc(260);
            }
        }

        &-brochure {
            &:global(.MuiButtonBase-root) {
                display: inline;
                position: relative;
                font-size: rem-calc(14);
                line-height: rem-calc(24);
                color: $color-green-darker;
                text-decoration: underline;
                text-underline-offset: rem-calc(5);
                text-decoration-color: $color-green;

                @media (min-width: $breakpoints-lg) {
                    font-size: rem-calc(16);
                }
            }

            span {
                white-space: nowrap;
                &::after {
                    content: '';
                    display: inline-block;
                    margin-left: rem-calc(4);
                    width: rem-calc(14);
                    height: rem-calc(14);
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.3355 7.23742H13.5022V0.93042H7.29316V2.09942H11.4965L8.02895 5.57609H0.902161V13.5304H8.83783V6.41702L12.3355 2.912V7.23742ZM7.67349 12.3614H2.06883V6.74275H7.67349V12.3614Z' fill='%23046138'/%3E%3C/svg%3E%0A");
                    background-size: cover;
                }
            }
        }
    }
}