@import '../../styles/_index.scss';

.masthead {
    scroll-margin-top: rem-calc(50);
    position: relative;
    padding-bottom: 125%; // 400x320 proportions

    background-image: url('../../images/hero-mobile.jpg');
    background-size: cover;
    background-position: center;

    @media (min-width: $breakpoints-md) {
        padding: 0;
        background: none;
    }

    &-container {
        @media (min-width: $breakpoints-md) {
            position: relative;
            padding-bottom: 34%; // 1024x350 proportions
            background-image: url('../../images/hero-desktop.jpg');
            background-size: cover;
            background-position: center;
        }

        @media (min-width: $breakpoints-lg) {
            padding: 0;
            height: rem-calc(350);
        }
    }

    &-content {
        position: absolute;
        bottom: rem-calc(18);
        left: rem-calc(24);
        right: rem-calc(24);
        color: $color-white;

        @media (min-width: $breakpoints-md) {
            left: rem-calc(50);
            right: auto;
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
        }

        em {
            font-family: $font-manulife-serif;
        }

        &-callout {
            margin-bottom: rem-calc(10);
            padding: rem-calc(9) rem-calc(24);
            background: $color-green-dark2;
            text-align: center;
            font-size: rem-calc(16);
            line-height: rem-calc(26);
            font-weight: 700;

            @media (min-width: $breakpoints-md) {
                display: inline-block;
            }

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(12);
                font-size: rem-calc(20);
                line-height: rem-calc(26);
            }
        }

        &-headline {
            margin: 0 0 rem-calc(10) 0;
            font-size: rem-calc(28);
            line-height: rem-calc(34);
            font-weight: 400;

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(24);
                font-size: rem-calc(46);
                line-height: rem-calc(57);
            }

            br {
                display: none;

                @media (min-width: $breakpoints-md) {
                    display: inline;
                }
            }
        }

        &-subtitle {
            margin-bottom: rem-calc(7);
            font-size: rem-calc(16);
            line-height: rem-calc(18);
            font-weight: 600;

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(16);
                font-size: rem-calc(24);
                line-height: rem-calc(28);
            }
        }

        &-tagline {
            font-size: rem-calc(16);
            line-height: rem-calc(24);

            @media (min-width: $breakpoints-lg) {
                font-size: rem-calc(22);
                line-height: rem-calc(24);
            }

            sup {
                font-size: rem-calc(6);
                vertical-align: rem-calc(8);

                @media (min-width: $breakpoints-lg) {
                    font-size: rem-calc(8);
                    vertical-align: rem-calc(11);
                }
            }

            em {
                font-family: $font-manulife-serif;
            }
        }
    }
}