@import '../../styles/_index.scss';

.footer {
    flex-grow: 0; // sticky footer
    flex-shrink: 0; // sticky footer
    padding: rem-calc(40) 0;
    background-color: $color-navy-darker;
    color: $color-white;
    font-weight: 500;
    line-height: rem-calc(18);

    @media (min-width: $breakpoints-lg) {
        padding: rem-calc(50) 0;
    }

    a {
        color: $color-green;
        text-decoration-color: $color-green;
    }

    &-footnotes {
        font-size: rem-calc(10);

        @media (min-width: $breakpoints-lg) {
            font-size: rem-calc(11);
        }
    }

    &-underwritten {
        padding-top: rem-calc(24);
        border-top: rem-calc(1) solid $color-gray-light;
        font-size: rem-calc(18);

        @media (min-width: $breakpoints-lg) {
            border-top: 0;
        }

        p {
            margin-top: 0;
        }

        small {
            font-size: rem-calc(10);

            @media (min-width: $breakpoints-lg) {
                font-size: rem-calc(11);
            }
        }

        &-logo {
            margin-bottom: rem-calc(5);
            height: rem-calc(28);
            width: auto;

            @media (min-width: $breakpoints-lg) {
                margin-bottom: rem-calc(8);
            }
        }
    }

    &-legal {
        font-size: rem-calc(12);

        @media (min-width: $breakpoints-lg) {
            font-size: rem-calc(14);
        }
    }
}